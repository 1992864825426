import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Fetches all at-bats for a game
export const getAtBatsByGame = async (gameId) => {
  try {
    const response = await api.get(`/atbats?game=${gameId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch at-bats by game:', error);
    throw error;
  }
};

// Record an at-bat for a player in a game
export const recordAtBat = async (gameId, playerId, atBatData) => {
  try {
    const response = await api.post(`/atbats/${gameId}/players/${playerId}`, atBatData);
    return response.data;
  } catch (error) {
    console.error('Failed to record at-bat:', error);
    throw error;
  }
};

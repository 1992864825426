import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Fetches all games by season ID
export const getGamesBySeasonId = async (seasonId) => {
  try {
    const response = await api.get(`/games?season=${seasonId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch games:', error);
    throw error;
  }
};

// Fetches a specific game by ID
export const getGameById = async (gameId) => {
  try {
    const response = await api.get(`/games/${gameId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch game by ID:', error);
    throw error;
  }
};

// Fetches the upcoming game by season ID
export const getUpcomingGame = async (seasonId) => {
  try {
    const response = await api.get('/games/upcoming', {
      params: { season: seasonId },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching upcoming game:', error);
    throw error;
  }
};

// Sets a game to started status after setting the batting order
export const startGame = async (gameId, battingOrder) => {
  try {
    const response = await api.put(`/games/start/${gameId}`, { battingOrder });
    return response.data;
  } catch (error) {
    console.error('Failed to start game:', error);
    throw error;
  }
};

// Updates a game by ID
export const updateGame = async (gameId, updatedGame) => {
  try {
    const response = await api.put(`/games/${gameId}`, updatedGame);
    return response.data;
  } catch (error) {
    console.error('Failed to update game:', error);
    throw error;
  }
};

// Updates the game status and sets the final score
export const updateGameStatusAndScore = async (gameId, status, ourTeamScore, otherTeamScore) => {
  try {
    const response = await api.put(`/games/${gameId}`, {
      status,
      finalScore: {
        ourTeam: ourTeamScore,
        otherTeam: otherTeamScore,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update game status and score:', error);
    throw error;
  }
};

// Deletes a game by ID
export const deleteGame = async (gameId) => {
  try {
    const response = await api.delete(`/games/${gameId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete game:', error);
    throw error;
  }
};

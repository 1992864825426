import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Fetches all players
export const getAllPlayers = async () => {
  try {
    const response = await api.get('/players');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch players:', error);
    throw error;
  }
};

// Fetches a specific player by ID
export const getPlayerById = async (id) => {
  try {
    const response = await api.get(`/players/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch player:', error);
    throw error;
  }
};

// Adds a player
export const addPlayer = async (playerData) => {
  try {
    const response = await api.post('/players', playerData);
    return response.data;
  } catch (error) {
    console.error('Failed to add player:', error);
    throw error;
  }
};

// Updates a player
export const updatePlayer = async (id, playerData) => {
  try {
    const response = await api.put(`/players/${id}`, playerData);
    return response.data;
  } catch (error) {
    console.error('Failed to update player:', error);
    throw error;
  }
};

// Deletes a player
export const deletePlayer = async (playerId) => {
  try {
    const response = await api.delete(`/players/${playerId}`);
    return response.data; // or handle the response in some other way
  } catch (error) {
    console.error('Failed to delete player:', error);
    throw error;
  }
};

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllPlayers } from '../services/PlayerService';
import { getAllSeasons } from '../services/SeasonService';
import { deletePlayer } from '../services/PlayerService';
import TopNavBar from './TopNavBar';
import BottomNavBar from './BottomNavBar';

interface Player {
  _id: string;
  name: string;
  batsThrows: string;
  positions: string[];
}

interface Season {
  _id: string;
  season: string;
  current: boolean;
  activePlayers: string[];
}

const ManageRoster: React.FC = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [selectedSeason, setSelectedSeason] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const seasonsData = await getAllSeasons();
        setSeasons(seasonsData);
        if (seasonsData.length > 0) {
          setSelectedSeason(seasonsData.find((season: Season) => season.current)?.season || seasonsData[0].season);
        }
      } catch (error) {
        console.error('Error fetching seasons:', error);
      }
    };

    fetchSeasons();
  }, []);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const playersData = await getAllPlayers();
        setPlayers(playersData);
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    };

    fetchPlayers();
  }, []);

  const activePlayers = players.filter((player) => {
    const season = seasons.find((season) => season.season === selectedSeason);
    return season?.activePlayers.includes(player._id);
  });

  const inactivePlayers = players.filter((player) => {
    const season = seasons.find((season) => season.season === selectedSeason);
    return !season?.activePlayers.includes(player._id);
  });

  const handleDeletePlayer = async (playerId: string) => {
    if (window.confirm('Are you sure you want to delete this player?')) {
      try {
        await deletePlayer(playerId);
        setPlayers((prev) => prev.filter((p) => p._id !== playerId)); // Update local state to remove player
      } catch (error) {
        console.error('Failed to delete player:', error);
        alert('Error deleting player');
      }
    }
  };

  return (
    <div className="mainMenuContainer">
      <TopNavBar />
      <h2>Manage Roster</h2>
      <button className="addFab" onClick={() => navigate('/add-player')}>
        +
      </button>

      <div className="player-select-container">
        <select onChange={(e) => setSelectedSeason(e.target.value)} value={selectedSeason}>
          {seasons.map((season) => (
            <option key={season._id} value={season.season}>
              {season.season}
            </option>
          ))}
        </select>
      </div>

      <h3>Active Players</h3>
      <div className="mainMenuContainer">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Bats / Throws</th>
              <th>Position(s)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {activePlayers.map((player) => (
              <tr key={player._id}>
                <td>{player.name}</td>
                <td>{player.batsThrows}</td>
                <td>{player.positions.join(', ')}</td>
                <td>
                  <button className="editButton" onClick={() => navigate(`/edit-player/${player._id}`)}>
                    Edit
                  </button>
                  <button className="deleteButton" onClick={() => handleDeletePlayer(player._id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <h3>Inactive Players</h3>
      <div className="mainMenuContainer">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Bats / Throws</th>
              <th>Position(s)</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {inactivePlayers.map((player) => (
              <tr key={player._id}>
                <td>{player.name}</td>
                <td>{player.batsThrows}</td>
                <td>{player.positions.join(', ')}</td>
                <td>
                  <button className="editButton" onClick={() => navigate(`/edit-player/${player._id}`)}>
                    Edit
                  </button>
                  <button className="deleteButton" onClick={() => handleDeletePlayer(player._id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <BottomNavBar />
    </div>
  );
};

export default ManageRoster;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { signIn, forgotPassword, confirmPassword } from '../services/AuthService';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [message, setMessage] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const [isNewPasswordRequired, setIsNewPasswordRequired] = useState(false); // NEW STATE TO HANDLE PASSWORD CHALLENGE
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the user was redirected after successfully resetting their password
  useEffect(() => {
    if (location.state && location.state.successMessage) {
      setMessage(location.state.successMessage);
    }
  }, [location.state]);

  const handleForgotPassword = async () => {
    setIsLoading(true);
    setMessage('');
    try {
      await forgotPassword(email);
      setMessage('Verification code sent to your email.');
      setIsVerificationCodeSent(true); // Show new password fields after sending code
    } catch (error: unknown) {
      if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage('An unknown error occurred.');
      }
    }
    setIsLoading(false);
  };

  const handleConfirmPassword = async () => {
    if (!verificationCode) {
      setMessage('Please enter the verification code.');
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setMessage('New passwords do not match.');
      return;
    }
    setIsLoading(true);
    try {
      await confirmPassword(email, verificationCode, newPassword);
      // Redirect to login page with success message
      navigate('/', {
        state: { successMessage: 'Password updated successfully! Please log in with your new password.' },
      });
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (error.message.includes('confirmationCode')) {
          setMessage('Please enter a valid verification code.');
        } else {
          setMessage(error.message);
        }
      } else {
        setMessage('An unknown error occurred.');
      }
    }
    setIsLoading(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');
    try {
      // Sign in the user
      await signIn(email, password);

      setMessage('Login successful!');
      navigate('/');
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (error.message === 'New password is required.') {
          // If a new password is required, show the new password fields
          setIsNewPasswordRequired(true);
        } else {
          setMessage(error.message);
        }
      } else {
        setMessage('An unknown error occurred.');
      }
    }
    setIsLoading(false);
  };

  const handleNewPasswordSubmit = async () => {
    if (newPassword !== confirmNewPassword) {
      setMessage('New passwords do not match.');
      return;
    }
    setIsLoading(true);
    try {
      // Call the signIn function with the new password to complete the challenge
      await signIn(email, password, newPassword);

      setMessage('Password updated successfully! Please log in with your new password.');
      setIsNewPasswordRequired(false);
      setPassword(''); // Clear password fields
      setNewPassword('');
      setConfirmNewPassword('');
      navigate('/'); // Redirect to the login page
    } catch (error: unknown) {
      if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage('An unknown error occurred.');
      }
    }
    setIsLoading(false);
  };

  return (
    <div>
      <div className="mainMenuContainer">
        <h2 className="login">{isForgotPassword ? 'Forgot Password' : isNewPasswordRequired ? 'Set New Password' : 'Login'}</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isVerificationCodeSent} // Disable email field after sending the code
            />
          </label>
          <br />
          {!isForgotPassword && !isNewPasswordRequired && (
            <>
              <label>
                Password:
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
              </label>
              <br />
              <button type="submit" className="actionButton">
                Login
              </button>
            </>
          )}

          {isForgotPassword && !isVerificationCodeSent && (
            <>
              <button type="button" className="actionButton" onClick={handleForgotPassword}>
                Send Verification Code
              </button>
            </>
          )}

          {isVerificationCodeSent && (
            <>
              <label>
                Verification Code:
                <input type="text" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} required />
              </label>
              <br />
              <label>
                New Password:
                <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
              </label>
              <br />
              <label>
                Confirm New Password:
                <input type="password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} required />
              </label>
              <br />
              <button type="button" className="actionButton" onClick={handleConfirmPassword}>
                Reset Password
              </button>
            </>
          )}

          {isNewPasswordRequired && (
            <>
              <label>
                New Password:
                <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
              </label>
              <br />
              <label>
                Confirm New Password:
                <input type="password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} required />
              </label>
              <br />
              <button type="button" className="actionButton" onClick={handleNewPasswordSubmit}>
                Set New Password
              </button>
            </>
          )}
        </form>

        {!isForgotPassword && !isNewPasswordRequired && (
          <p className="forgotPassword" onClick={() => setIsForgotPassword(true)}>
            Forgot Password?
          </p>
        )}
        {isForgotPassword && !isVerificationCodeSent && (
          <p className="backToLogin" onClick={() => setIsForgotPassword(false)}>
            Back to Login
          </p>
        )}
        {isVerificationCodeSent && (
          <p
            className="backToLogin"
            onClick={() => {
              setIsForgotPassword(false);
              setIsVerificationCodeSent(false);
            }}
          >
            Cancel Reset Password
          </p>
        )}
        {isLoading && <p className="loading">Processing...</p>}
        {!isLoading && message && <p className={isVerificationCodeSent || isNewPasswordRequired ? 'success' : 'error'}>{message}</p>}
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getGameById, updateGameStatusAndScore } from '../services/GameService';
import { getAtBatsByGame } from '../services/AtBatService'; // Updated import
import TopNavBar from './TopNavBar';
import BottomNavBar from './BottomNavBar';
import FinalScoreModal from './FinalScoreModal';

interface AtBat {
  game: string;
  player: Player;
  resultType: string;
  hitType?: string;
  hitLocation?: string;
  baseHit?: string;
  RBIs: number;
}

interface Player {
  _id: string;
  name: string;
  atBatsCount: number;
  hits: number;
  results: string[];
  RBIs: number;
}

interface Team {
  _id: string;
  name: string;
  logo: string;
}
interface Game {
  _id: string;
  date: string;
  opponent: Team;
  status: string;
  battingOrder: Player[];
  finalScore?: {
    ourTeam: number;
    otherTeam: number;
  };
}

const UpdateStats: React.FC = () => {
  const { gameId } = useParams<{ gameId: string }>();
  const navigate = useNavigate();
  const [game, setGame] = useState<Game | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchGameData = useCallback(async () => {
    try {
      const gameData = await getGameById(gameId!);

      // Process player data from the populated battingOrder
      const processedPlayers = gameData.battingOrder.map((player: Player) => ({
        ...player,
        atBatsCount: 0,
        hits: 0,
        results: [],
        RBIs: 0,
      }));

      // Fetch all at-bats for the game
      const atBatsForGame: AtBat[] = await getAtBatsByGame(gameId!);

      // Process at-bats for each player
      processedPlayers.forEach((player: Player) => {
        const playerAtBats = atBatsForGame.filter((atBat) => atBat.player._id === player._id);

        player.atBatsCount = playerAtBats.filter((atBat) => !(atBat.resultType === 'Flyout' && atBat.RBIs > 0) && atBat.resultType !== 'Walk').length;
        player.hits = playerAtBats.filter((atBat) => atBat.resultType === 'Hit').length;
        player.results = playerAtBats.map((atBat) => {
          if (atBat.resultType === 'Flyout' && atBat.RBIs > 0) {
            return 'SF'; // Sacrifice Fly
          }
          switch (atBat.resultType) {
            case 'Hit':
              switch (atBat.baseHit) {
                case 'Single':
                  return '1B';
                case 'Double':
                  return '2B';
                case 'Triple':
                  return '3B';
                case 'Homerun':
                  return 'HR';
                default:
                  return 'Hit';
              }
            case 'Strikeout':
              return 'K';
            case 'Walk':
              return 'BB';
            case 'Groundout':
            case 'Flyout':
              return 'Out';
            default:
              return atBat.resultType;
          }
        });
        player.RBIs = playerAtBats.reduce((total, atBat) => total + atBat.RBIs, 0);
      });

      setGame({ ...gameData, battingOrder: processedPlayers });
      setLoading(false);
    } catch (error: any) {
      console.error('Failed to fetch game:', error);
      setError('Failed to fetch game');
      setLoading(false);
    }
  }, [gameId]);

  useEffect(() => {
    fetchGameData();
  }, [fetchGameData]);

  const handleRecordAtBat = (playerId: string) => {
    navigate(`/record-at-bat/${gameId}/${playerId}`);
  };

  const handleFinishGame = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmitFinalScores = async (ourTeamScore: number, otherTeamScore: number) => {
    try {
      await updateGameStatusAndScore(gameId!, 'finished', ourTeamScore, otherTeamScore);
      alert('Game finished successfully!');
      setIsModalOpen(false);
      fetchGameData(); // Refresh the game data to reflect the final score and status
    } catch (error) {
      console.error('Failed to finish game:', error);
      alert('Failed to finish game. Please try again.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!game) {
    return <div>No game data available.</div>;
  }

  return (
    <div className="mainMenuContainer">
      <TopNavBar />
      <h2>
        The Hacks vs. {game.opponent?.name} ({new Date(game.date).toLocaleDateString()})
      </h2>
      <table>
        <thead>
          <tr>
            <th>Player Name</th>
            <th>At Bats</th>
            <th>Hits</th>
            <th>Results</th>
            <th>RBIs</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {game.battingOrder.map((player: Player) => (
            <tr key={player._id}>
              <td>{player.name}</td>
              <td>{player.atBatsCount}</td>
              <td>{player.hits}</td>
              <td>{Array.isArray(player.results) ? player.results.join(', ') : ''}</td>
              <td>{player.RBIs}</td>
              <td>
                <button className="tableActionButton" onClick={() => handleRecordAtBat(player._id)}>
                  Record At Bat
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button className="actionButton" onClick={handleFinishGame}>
        Finish Game
      </button>

      <FinalScoreModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleSubmitFinalScores} />

      <BottomNavBar />
    </div>
  );
};

export default UpdateStats;

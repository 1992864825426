import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated, getUserAttributes } from '../services/AuthService';

interface PrivateRouteProps {
  element: React.ComponentType<any>;
  adminOnly?: boolean; // Optionally check if the route is for admins only
  path?: string; // Optionally include other props like `path`
  [key: string]: any; // Allow any other props
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element: Component, adminOnly = false, ...rest }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const authenticated = await isAuthenticated();
        setIsAuth(authenticated);
        if (authenticated) {
          const userAttributes = await getUserAttributes();
          setIsAdmin(userAttributes['custom:isAdmin'] === 'true');
        }
      } catch {
        setIsAuth(false);
      }
      setIsLoading(false);
    };

    checkAuth();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  if (!isAuth) {
    return <Navigate to="/login" />;
  }

  if (adminOnly && !isAdmin) {
    return <Navigate to="/not-authorized" />; // You can create a not-authorized page or component
  }

  return <Component {...rest} />;
};

export default PrivateRoute;

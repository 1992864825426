import React, { useEffect, useState } from 'react';
import { getUserAttributes, forgotPassword, confirmPassword } from '../services/AuthService';
import { FaTimes } from 'react-icons/fa';

interface ProfileModalProps {
  onClose: () => void;
  onSignOut: () => void;
}

const ProfileModal: React.FC<ProfileModalProps> = ({ onClose, onSignOut }) => {
  const [userAttributes, setUserAttributes] = useState<{ [key: string]: string }>({});
  const [message, setMessage] = useState('');
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const attributes = await getUserAttributes();
        setUserAttributes(attributes);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
        alert('Failed to fetch user attributes.');
      }
    };

    fetchUserAttributes();
  }, []);

  const handlePasswordReset = async () => {
    try {
      await forgotPassword(userAttributes.email);
      setIsPasswordReset(true);
      setMessage('Password reset code sent to your email.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setMessage('Failed to send password reset email.');
    }
  };

  const handleConfirmPassword = async () => {
    try {
      await confirmPassword(userAttributes.email, verificationCode, newPassword);
      setMessage('Password reset successfully. Please log in with your new password.');
      setIsPasswordReset(false);
      setVerificationCode('');
      setNewPassword('');
    } catch (error) {
      console.error('Error resetting password:', error);
      setMessage('Failed to reset password.');
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          <FaTimes />
        </span>
        <h2>Profile</h2>
        <div className="profileInfo">
          <div className="profileItem">
            <label>Email:</label>
            <div>{userAttributes.email}</div>
          </div>
        </div>
        {!isPasswordReset && (
          <>
            <button className="changePasswordButton" onClick={handlePasswordReset}>
              Change Password
            </button>
            <button className="signOutButton" onClick={onSignOut}>
              Sign Out
            </button>
          </>
        )}
        {isPasswordReset && (
          <>
            <div className="passwordResetForm">
              <label>
                Verification Code:
                <input type="text" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} required />
              </label>
              <label>
                New Password:
                <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
              </label>
              <button className="confirmPasswordButton" onClick={handleConfirmPassword}>
                Reset Password
              </button>
            </div>
          </>
        )}
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default ProfileModal;

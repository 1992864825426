import React, { useState, ChangeEvent, FormEvent } from 'react';

interface FinalScoreModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (ourTeamScore: number, otherTeamScore: number) => void;
}

const FinalScoreModal: React.FC<FinalScoreModalProps> = ({ isOpen, onClose, onSubmit }) => {
    const [ourTeamScore, setOurTeamScore] = useState<number>(0);
    const [otherTeamScore, setOtherTeamScore] = useState<number>(0);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(ourTeamScore, otherTeamScore);
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <h2>Enter Final Scores</h2>
                <form onSubmit={handleSubmit}>
                    <label>
                        Our Team:
                        <input
                            type="number"
                            value={ourTeamScore}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setOurTeamScore(parseInt(e.target.value))}
                            min="0"
                            required
                        />
                    </label>
                    <label>
                        Other Team:
                        <input
                            type="number"
                            value={otherTeamScore}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setOtherTeamScore(parseInt(e.target.value))}
                            min="0"
                            required
                        />
                    </label>
                    <button type="submit" className="actionButton">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default FinalScoreModal;

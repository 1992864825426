import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPlayerById, updatePlayer } from '../services/PlayerService';
import TopNavBar from './TopNavBar';
import BottomNavBar from './BottomNavBar';

const positions = ['P', 'C', '1B', '2B', '3B', 'SS', 'LF', 'LCF', 'RCF', 'RF'];
const batsThrows = ['R/R', 'L/L', 'R/L', 'L/R'];
const status = ['Active', 'Sub', 'Inactive'];

interface Player {
    name: string;
    positions: string[];
    batsThrows: string;
    status: string;
}

const EditPlayer: React.FC = () => {
    const [player, setPlayer] = useState<Player>({ name: '', positions: [], batsThrows: '', status: '' });
    const { playerId } = useParams<{ playerId: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPlayer = async () => {
            if (playerId) {
                try {
                    const playerData = await getPlayerById(playerId);
                    setPlayer({
                        ...playerData,
                        positions: playerData.positions || [] // Ensure positions is an array
                    });
                } catch (error) {
                    console.error('Failed to fetch player details:', error);
                    alert('Failed to fetch player details');
                }
            }
        };
    
        fetchPlayer();
    }, [playerId]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = event.target;
        if (type === 'checkbox') {
            // Handling checkbox for 'positions' specifically
            const isChecked = (event.target as HTMLInputElement).checked;
            setPlayer(prevPlayer => {
                const newPositions = isChecked 
                    ? [...prevPlayer.positions, value]
                    : prevPlayer.positions.filter(pos => pos !== value);
                return { ...prevPlayer, positions: newPositions };
            });
        } else {
            // Handling all other inputs
            setPlayer(prevPlayer => ({ ...prevPlayer, [name]: value }));
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await updatePlayer(playerId, player);
            navigate('/manage-roster');
        } catch (error) {
            console.error('Failed to update player:', error);
            alert('Error updating player');
        }
    };    

    return (
        <div className="mainMenuContainer">
            <TopNavBar />
            <h2>Edit Player</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Name:
                    <input type="text" name="name" value={player.name} onChange={handleChange} required />
                </label>
                <label>
                    Bats / Throws:
                    <select name="batsThrows" value={player.batsThrows} onChange={handleChange} required>
                        {batsThrows.map(bt => (
                            <option key={bt} value={bt}>
                                {bt}
                            </option>
                        ))}
                    </select>
                </label>
                <label>
                    Status:
                    <select name="status" value={player.status} onChange={handleChange} required>
                        {status.map(st => (
                            <option key={st} value={st}>
                                {st}
                            </option>
                        ))}
                    </select>
                </label>
                <fieldset className="positionsFieldset">
                    <legend className="fieldsetLegend">Positions</legend>
                    {positions.map(pos => (
                        <label key={pos} className="positionCheckbox">
                            <input
                                type="checkbox"
                                name="positions"
                                value={pos}
                                checked={player.positions.includes(pos)}
                                onChange={handleChange}
                            /> {pos}
                        </label>
                    ))}
                </fieldset>
                <div className="formButtons">
                    <button type="submit" className="actionButton">Update Player</button>
                </div>
            </form>
            <BottomNavBar />
        </div>
    );
};

export default EditPlayer;

import React, { useState, useEffect } from 'react';
import { getPlayerStats, getWeeklyStats } from '../services/StatsService';
import { getCurrentSeason } from '../services/SeasonService';
import { getUpcomingGame } from '../services/GameService';
import { getUserAttributes } from '../services/AuthService';
import TopNavBar from './TopNavBar';
import BottomNavBar from './BottomNavBar';

interface PlayerStats {
  G: number;
  AB: number;
  H: number;
  '2B': number;
  '3B': number;
  HR: number;
  RBI: number;
  BB: number;
  SO: number;
  AVG: string;
  OBP: string;
  SLG: string;
  OPS: string;
}

interface Game {
  date: string;
  opponent: Team;
  field?: string;
}

interface Team {
  name: string;
  logo: string;
}

const MainMenu: React.FC = () => {
  const [seasonStats, setSeasonStats] = useState<PlayerStats | null>(null);
  const [weeklyStats, setWeeklyStats] = useState<PlayerStats | null>(null);
  const [upcomingGame, setUpcomingGame] = useState<Game | null>(null);
  const [currentSeasonId, setCurrentSeasonId] = useState<string | null>(null);
  const [currentPlayerId, setCurrentPlayerId] = useState<string | null>(null);

  const ourTeam: Team = {
    name: 'The Hacks',
    logo: 'TheHacks',
  };

  useEffect(() => {
    const fetchCurrentSeason = async () => {
      try {
        const currentSeason = await getCurrentSeason();
        setCurrentSeasonId(currentSeason._id);
      } catch (error) {
        console.error('Error fetching current season or team details:', error);
      }
    };

    fetchCurrentSeason();
  }, []);

  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const attributes = await getUserAttributes();
        setCurrentPlayerId(attributes['custom:playerId'] || null);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };

    fetchUserAttributes();
  }, []);

  useEffect(() => {
    if (currentPlayerId) {
      const fetchWeeklyStats = async () => {
        try {
          const weeklyStatsResponse = await getWeeklyStats(currentPlayerId, null, true);
          setWeeklyStats(weeklyStatsResponse.stats);
        } catch (error) {
          console.error('Error fetching weekly stats:', error);
        }
      };

      fetchWeeklyStats();
    }
  }, [currentPlayerId]);

  useEffect(() => {
    if (currentPlayerId && currentSeasonId) {
      const fetchStats = async () => {
        try {
          const seasonStatsData = await getPlayerStats(currentPlayerId, 'season', currentSeasonId);
          setSeasonStats(seasonStatsData);
        } catch (error) {
          console.error('Error fetching player stats:', error);
        }
      };

      fetchStats();
    }
  }, [currentPlayerId, currentSeasonId]);

  useEffect(() => {
    const fetchUpcomingGameAndOpponent = async () => {
      try {
        const game = await getUpcomingGame(currentSeasonId);
        setUpcomingGame(game);
      } catch (error) {
        console.error('Error fetching upcoming game or opponent details:', error);
      }
    };

    if (currentSeasonId) {
      fetchUpcomingGameAndOpponent();
    }
  }, [currentSeasonId]);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const day = date.getDate();
    const suffix = day % 10 === 1 && day !== 11 ? 'st' : day % 10 === 2 && day !== 12 ? 'nd' : day % 10 === 3 && day !== 13 ? 'rd' : 'th';
    return date.toLocaleDateString('en-US', options).replace(/\d{1,2}/, `${day}${suffix}`);
  };

  const renderWeeklyStats = () => {
    if (!weeklyStats) {
      return <p>Loading weekly stats...</p>;
    }

    const { AB, H, '2B': doubles, '3B': triples, HR, RBI, AVG } = weeklyStats;
    const extraBaseHits = doubles + triples + HR;

    return (
      <div className="weeklyStatsContainer">
        <h5>Recent Week</h5>
        <p className="weeklyStatsLine">
          <span className="highlight">
            {H} for {AB}
          </span>{' '}
          |<span className="highlight"> {extraBaseHits} XBH</span> |<span className="highlight"> {HR} HR</span> |
          <span className="highlight"> {RBI} RBI</span> |<span className="highlight"> {AVG} AVG</span>
        </p>
      </div>
    );
  };

  const renderSeasonStats = () => {
    if (!seasonStats) {
      return <p>Loading season stats...</p>;
    }

    return (
      <table>
        <thead>
          <tr>
            <th>G</th>
            <th>AB</th>
            <th>H</th>
            <th>2B</th>
            <th>3B</th>
            <th>HR</th>
            <th>RBI</th>
            <th>BB</th>
            <th>SO</th>
            <th>AVG</th>
            <th>OBP</th>
            <th>SLG</th>
            <th>OPS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{seasonStats.G}</td>
            <td>{seasonStats.AB}</td>
            <td>{seasonStats.H}</td>
            <td>{seasonStats['2B']}</td>
            <td>{seasonStats['3B']}</td>
            <td>{seasonStats.HR}</td>
            <td>{seasonStats.RBI}</td>
            <td>{seasonStats.BB}</td>
            <td>{seasonStats.SO}</td>
            <td>{seasonStats.AVG}</td>
            <td>{seasonStats.OBP}</td>
            <td>{seasonStats.SLG}</td>
            <td>{seasonStats.OPS}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderUpcomingGame = () => {
    if (!upcomingGame || !ourTeam) {
      return <p>Loading upcoming game...</p>;
    }

    const { opponent, date, field } = upcomingGame;

    const formattedDate = formatDate(date);

    const ourTeamLogo = `/logos/${ourTeam.logo}.png`;
    const opponentTeamLogo = `/logos/${opponent.logo}.png`;

    return (
      <>
        <div className="gameDetails">
          <h4 className="glowing">{formattedDate}</h4>
          <h4 className="glowing">{field || 'TBD'}</h4>
        </div>
        <div className="upcomingGameContainer">
          <div className="teamContainer">
            <img src={ourTeamLogo} alt={ourTeam.name} />
            <h3>{ourTeam.name}</h3>
          </div>
          <div className="teamContainer">
            <h3>VS</h3>
          </div>
          <div className="teamContainer">
            <img src={opponentTeamLogo} alt={opponent.name} />
            <h3>{opponent.name}</h3>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="mainMenuContainer">
      <TopNavBar />
      <h1>HackTracker</h1>
      <h3>Home of The Hacks Softball Team</h3>

      {renderUpcomingGame()}

      {/* Conditionally render Player Dashboard header and Weekly Stats */}
      {weeklyStats && <h3>Player Dashboard</h3>}
      {weeklyStats && <div className="statsContainer">{renderWeeklyStats()}</div>}

      {/* Always render Season Stats */}
      <div className="statsContainer">
        <h4>Your Season Stats</h4>
        {renderSeasonStats()}
      </div>

      <BottomNavBar />
    </div>
  );
};

export default MainMenu;

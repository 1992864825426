import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import MainMenu from './components/MainMenu';
import ViewPlayerStats from './components/ViewPlayerStats';
import ManageRoster from './components/ManageRoster';
import ManageSeasons from './components/ManageSeasons';
import CreateSeason from './components/CreateSeason';
import SeasonTeams from './components/SeasonTeams';
import SeasonGames from './components/SeasonGames';
import AddPlayer from './components/AddPlayer';
import EditPlayer from './components/EditPlayer';
import EditSeason from './components/EditSeason';
import UpdateStats from './components/UpdateStats';
import RecordAtBat from './components/RecordAtBat';
import ManageTeams from './components/ManageTeams';
import Heatmap from './components/Heatmap';
import CoachDashboard from './components/CoachDashboard';
import NotFound from './components/NotFound';
import PrivateRoute from './components/PrivateRoute';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute element={MainMenu} />} />
        <Route path="/view-player-stats" element={<PrivateRoute element={ViewPlayerStats} />} />
        <Route path="/hitting-heatmap" element={<PrivateRoute element={Heatmap} />} />
        <Route path="/coach-dashboard" element={<PrivateRoute element={CoachDashboard} adminOnly />} />
        <Route path="/manage-roster" element={<PrivateRoute element={ManageRoster} />} />
        <Route path="/manage-seasons" element={<PrivateRoute element={ManageSeasons} />} />
        <Route path="/add-player" element={<PrivateRoute element={AddPlayer} />} />
        <Route path="/manage-teams" element={<PrivateRoute element={ManageTeams} />} />
        <Route path="/create-season" element={<PrivateRoute element={CreateSeason} />} />
        <Route path="/season-teams/:seasonId" element={<PrivateRoute element={SeasonTeams} />} />
        <Route path="/season-games/:seasonId" element={<PrivateRoute element={SeasonGames} />} />
        <Route path="/edit-player/:playerId" element={<PrivateRoute element={EditPlayer} />} />
        <Route path="/edit-season/:seasonId" element={<PrivateRoute element={EditSeason} />} />
        <Route path="/update-stats/:gameId" element={<PrivateRoute element={UpdateStats} />} />
        <Route path="/record-at-bat/:gameId/:playerId" element={<PrivateRoute element={RecordAtBat} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;

import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { addGameToSeason, getCurrentSeason } from '../services/SeasonService'; // Assuming this is correct

interface Team {
  _id: string;
  name: string;
  logo: string;
}

interface AddGameModalProps {
  season: any;
  onClose: () => void;
}

const AddGameModal: React.FC<AddGameModalProps> = ({ season, onClose }) => {
  const [game, setGame] = useState({
    date: '',
    time: '18:30', // Default to 6:30 PM
    opponent: '',
    gameType: 'Regular',
    gameNumber: '1',
    status: 'not started',
    field: '', // New field for entering the game location/field
  });

  const [teams, setTeams] = useState<Team[]>([]); // Array of team objects

  useEffect(() => {
    const fetchCurrentSeason = async () => {
      try {
        const currentSeason = await getCurrentSeason();
        setTeams(currentSeason.teams); // Set teams as objects
        if (currentSeason.teams.length > 0) {
          setGame((prev) => ({ ...prev, opponent: currentSeason.teams[0]._id })); // Set opponent as the first team ID
        }
      } catch (error) {
        console.error('Failed to fetch current season:', error);
      }
    };

    fetchCurrentSeason();
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setGame((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const date = new Date(`${game.date}T${game.time}:00`).toISOString();
    try {
      await addGameToSeason(season._id, { ...game, date });
      alert('Game added successfully!');
      onClose();
    } catch (error: any) {
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <h2>Add Game to {season.season}</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Date:
            <input type="date" name="date" value={game.date} onChange={handleChange} required />
          </label>
          <label>
            Time:
            <select name="time" value={game.time} onChange={handleChange} required>
              <option value="18:30">6:30 PM MST</option>
              <option value="19:30">7:30 PM MST</option>
              <option value="20:30">8:30 PM MST</option>
              <option value="21:30">9:30 PM MST</option>
            </select>
          </label>
          {/* New Field Input */}
          <label>
            Field:
            <input type="text" name="field" value={game.field} onChange={handleChange} placeholder="Enter field location" required />
          </label>
          <label>
            Opponent:
            <select name="opponent" value={game.opponent} onChange={handleChange} required>
              {teams.map((team) => (
                <option key={team._id} value={team._id}>
                  {team.name} {/* Render team name in the dropdown */}
                </option>
              ))}
            </select>
          </label>
          <label>
            Game Type:
            <select name="gameType" value={game.gameType} onChange={handleChange} required>
              <option value="Regular">Regular</option>
              <option value="Postseason">Postseason</option>
            </select>
          </label>
          {game.gameType === 'Regular' && (
            <label>
              Game Number:
              <select name="gameNumber" value={game.gameNumber} onChange={handleChange} required>
                <option value="1">Game 1</option>
                <option value="2">Game 2</option>
              </select>
            </label>
          )}
          <button type="submit" className="actionButton">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddGameModal;

// CoachDashboard.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BottomNavBar from './BottomNavBar';
import TopNavBar from './TopNavBar';
import CreateAccountModal from './CreateAccountModal';

const CoachDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="mainMenuContainer">
      <TopNavBar />
      <h1>HackTracker</h1>
      <h3>The official slowpitch softball stat tracker of The Hacks</h3>
      <div className="buttonContainer">
        <button className="button" onClick={() => navigate('/manage-roster')}>
          View Roster
        </button>
        <button className="button" onClick={() => navigate('/manage-seasons')}>
          View Seasons
        </button>
        <button className="button" onClick={() => navigate('/manage-teams')}>
          View Teams
        </button>
        <button className="button" onClick={openModal}>
          Create Account
        </button>
      </div>
      <BottomNavBar />
      {isModalOpen && <CreateAccountModal onClose={closeModal} />}
    </div>
  );
};

export default CoachDashboard;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getSeasonById } from '../services/SeasonService';
import { getGamesBySeasonId, deleteGame, updateGame, startGame } from '../services/GameService';
import { getAllPlayers } from '../services/PlayerService';
import { getUserAttributes } from '../services/AuthService';
import EditGameModal from '../components/EditGameModal';
import LineupModal from '../components/LineupModal';
import TopNavBar from './TopNavBar';
import BottomNavBar from './BottomNavBar';
import { Popover, PopoverBody, Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

interface Player {
  _id: string;
  name: string;
  positions: string[];
  status: string;
  batsThrows: string;
}

interface Team {
  _id: string;
  name: string;
}

interface Game {
  _id: string;
  date: string;
  opponent: Team; // Ensure opponent is a Team object
  field?: string;
  players: string[];
  gameNumber?: number;
  gameType: string;
  status: string;
  finalScore?: {
    ourTeam: number;
    otherTeam: number;
  };
}

interface Season {
  _id: string;
  season: string;
  location: string;
  league: string;
  teams: string[];
  current: boolean;
  startDate: Date;
}

const SeasonGames: React.FC = () => {
  const { seasonId } = useParams<{ seasonId: string }>();
  const navigate = useNavigate();
  const [season, setSeason] = useState<Season | null>(null);
  const [games, setGames] = useState<Game[]>([]);
  const [players, setPlayers] = useState<Player[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isStartGameModalOpen, setIsStartGameModalOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState<Game | null>(null);
  const [popoverOpen, setPopoverOpen] = useState<{ [key: string]: boolean }>({});
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isUserFetched, setIsUserFetched] = useState<boolean>(false);

  useEffect(() => {
    const fetchSeasonAndGames = async () => {
      try {
        const fetchedSeason = await getSeasonById(seasonId!);
        const fetchedGames = await getGamesBySeasonId(seasonId!);

        // Sort games to show the most recent at the bottom
        const sortedGames = fetchedGames.sort((a: Game, b: Game) => new Date(a.date).getTime() - new Date(b.date).getTime());

        setSeason(fetchedSeason);
        setGames(sortedGames);
        setLoading(false);
      } catch (error: any) {
        console.error('Failed to fetch season or games:', error);
        setError('Failed to fetch season or games');
        setLoading(false);
      }
    };

    const fetchPlayers = async () => {
      try {
        const allPlayers = await getAllPlayers();
        const activePlayers = allPlayers.filter((player: Player) => player.status !== 'Inactive');
        setPlayers(activePlayers);
      } catch (error) {
        console.error('Failed to fetch players:', error);
      }
    };

    const fetchUserAttributes = async () => {
      try {
        const attributes = await getUserAttributes();
        setIsAdmin(attributes['custom:isAdmin'] === 'true');
        setIsUserFetched(true);
      } catch (error) {
        console.error('Error fetching user attributes:', error);
        setIsUserFetched(true);
      }
    };

    fetchSeasonAndGames();
    fetchPlayers();
    fetchUserAttributes();
  }, [seasonId]);

  const togglePopover = (gameId: string) => {
    setPopoverOpen((prev) => ({ ...prev, [gameId]: !prev[gameId] }));
  };

  const handleDeleteGame = async (gameId: string) => {
    if (window.confirm('Are you sure you want to delete this game?')) {
      try {
        await deleteGame(gameId);
        setGames((prevGames) => prevGames.filter((game) => game._id !== gameId));
      } catch (error) {
        console.error('Failed to delete game:', error);
        alert('Error deleting game');
      }
    }
  };

  const handleEditGame = (game: Game) => {
    setSelectedGame(game);
    setIsEditModalOpen(true);
  };

  const handleSaveGame = async (updatedGame: Game) => {
    try {
      await updateGame(updatedGame._id, updatedGame);
      setGames((prevGames) => prevGames.map((game) => (game._id === updatedGame._id ? updatedGame : game)));
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Failed to update game:', error);
      alert('Failed to update game. Please try again.');
    }
  };

  const handleStartGame = (game: Game) => {
    setSelectedGame(game);
    setIsStartGameModalOpen(true);
  };

  const handleConfirmStartGame = async (battingOrder: Player[]) => {
    if (!selectedGame) return;
    try {
      await startGame(
        selectedGame._id,
        battingOrder.map((player) => player._id)
      );
      setGames((prevGames) => prevGames.map((game) => (game._id === selectedGame._id ? { ...game, battingOrder, status: 'in progress' } : game)));
      setIsStartGameModalOpen(false);
    } catch (error) {
      console.error('Failed to start game:', error);
      alert('Failed to start game. Please try again.');
    }
  };

  const convertUTCToMST = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', { timeZone: 'America/Phoenix', hour: '2-digit', minute: '2-digit', hour12: true });
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
  };

  const getResult = (game: Game): string => {
    if (!game.finalScore || (game.finalScore.ourTeam === 0 && game.finalScore.otherTeam === 0)) {
      return 'TBD';
    }
    const { ourTeam, otherTeam } = game.finalScore || { ourTeam: 0, otherTeam: 0 };
    return `${ourTeam} - ${otherTeam}`;
  };

  const getResultColor = (game: Game): string => {
    if (!game.finalScore || (game.finalScore.ourTeam === 0 && game.finalScore.otherTeam === 0)) {
      return 'black';
    }
    const { ourTeam, otherTeam } = game.finalScore || { ourTeam: 0, otherTeam: 0 };
    return ourTeam > otherTeam ? 'green' : 'red';
  };

  if (loading || !isUserFetched) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!season) {
    return <div>No season data available.</div>;
  }

  return (
    <div className="mainMenuContainer">
      <TopNavBar />
      <h2>{season.season} Schedule</h2>
      <h3>{season.location}</h3>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Start Time</th>
            <th>Field</th>
            <th>Opponent</th>
            <th>Final Score</th>
            {isAdmin && <th>Admin</th>}
          </tr>
        </thead>
        <tbody>
          {games.map((game) => (
            <tr key={game._id}>
              <td>{formatDate(game.date)}</td>
              <td>{convertUTCToMST(game.date)}</td>
              <td>{game.field || '?'}</td>
              <td>{game.opponent.name}</td> {/* opponent is now a Team object */}
              <td style={{ color: getResultColor(game) }}>{getResult(game)}</td>
              {isAdmin && (
                <td>
                  <Button id={`Popover-${game._id}`} className="editButton">
                    Action
                  </Button>
                  <Popover
                    placement="bottom"
                    isOpen={popoverOpen[game._id] || false}
                    target={`Popover-${game._id}`}
                    toggle={() => togglePopover(game._id)}
                    className="popoverAction"
                  >
                    <PopoverBody className="popoverBody">
                      <button className="popoverItem" onClick={() => handleStartGame(game)}>
                        Start Game
                      </button>
                      <button className="popoverItem" onClick={() => navigate(`/update-stats/${game._id}`)}>
                        Update Stats
                      </button>
                      <button className="popoverItem" onClick={() => handleEditGame(game)}>
                        Edit
                      </button>
                      <button className="popoverItem" onClick={() => handleDeleteGame(game._id)}>
                        Delete
                      </button>
                    </PopoverBody>
                  </Popover>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {isEditModalOpen && selectedGame && <EditGameModal game={selectedGame} onSave={handleSaveGame} onClose={() => setIsEditModalOpen(false)} />}
      {isStartGameModalOpen && (
        <LineupModal
          isOpen={isStartGameModalOpen}
          players={players}
          onClose={() => setIsStartGameModalOpen(false)}
          onConfirm={handleConfirmStartGame}
        />
      )}
      <BottomNavBar />
    </div>
  );
};

export default SeasonGames;

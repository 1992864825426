import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaChartBar, FaMapMarkerAlt, FaCalendarAlt, FaUser } from 'react-icons/fa';
import { getCurrentSeason } from '../services/SeasonService';
import { getUserAttributes } from '../services/AuthService';

const BottomNavBar: React.FC = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserAttributes = async () => {
      try {
        const attributes = await getUserAttributes();
        setIsAdmin(attributes['custom:isAdmin'] === 'true');
      } catch (error) {
        console.error('Error fetching user attributes:', error);
      }
    };

    fetchUserAttributes();
  }, []);

  const handleScheduleClick = async () => {
    try {
      const currentSeason = await getCurrentSeason();
      if (currentSeason && currentSeason._id) {
        navigate(`/season-games/${currentSeason._id}`);
      } else {
        alert('No current season found.');
      }
    } catch (error) {
      console.error('Error fetching current season:', error);
      alert('Failed to fetch the current season.');
    }
  };

  return (
    <div className="bottomNavBar">
      <button className="navButton" onClick={() => navigate('/')}>
        <FaHome />
        <span>Home</span>
      </button>
      <button className="navButton" onClick={() => navigate('/view-player-stats')}>
        <FaChartBar />
        <span>Stats</span>
      </button>
      <button className="navButton" onClick={() => navigate('/hitting-heatmap')}>
        <FaMapMarkerAlt />
        <span>Heatmap</span>
      </button>
      <button className="navButton" onClick={handleScheduleClick}>
        <FaCalendarAlt />
        <span>Schedule</span>
      </button>
      {isAdmin && (
        <button className="navButton" onClick={() => navigate('/coach-dashboard')}>
          <FaUser />
          <span>Coach</span>
        </button>
      )}
    </div>
  );
};

export default BottomNavBar;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllSeasons, deleteSeason } from '../services/SeasonService';
import { getUserAttributes } from '../services/AuthService'; // Import the getUserAttributes function
import TopNavBar from './TopNavBar';
import BottomNavBar from './BottomNavBar';
import AddGameModal from './AddGameModal';
import { Popover, PopoverBody, Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

interface Season {
  _id: string;
  season: string;
  location: string;
  league: string;
  current: boolean;
  startDate: Date;
}

const ManageSeasons: React.FC = () => {
  const [seasons, setSeasons] = useState<Season[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedSeason, setSelectedSeason] = useState<Season | null>(null);
  const [popoverOpen, setPopoverOpen] = useState<{ [key: string]: boolean }>({});
  const [isAdmin, setIsAdmin] = useState<boolean>(false); // State to store admin status
  const [isUserFetched, setIsUserFetched] = useState<boolean>(false); // State to check if user attributes are fetched
  const navigate = useNavigate();

  const fetchSeasons = async () => {
    try {
      setLoading(true);
      const data = await getAllSeasons();
      const sortedSeasons = data.sort((a: Season, b: Season) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
      setSeasons(sortedSeasons);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setError('Failed to fetch seasons');
      console.error('Error fetching seasons:', error);
    }
  };

  const fetchUserAttributes = async () => {
    try {
      const attributes = await getUserAttributes();
      console.log('User attributes:', attributes); // Logging user attributes
      setIsAdmin(attributes['custom:isAdmin'] === 'true'); // Set admin status based on user attributes
      setIsUserFetched(true); // Set user fetched state to true after fetching attributes
    } catch (error) {
      console.error('Error fetching user attributes:', error);
      setIsUserFetched(true); // Set user fetched state to true even if there is an error
    }
  };

  const handleDeleteSeason = async (seasonId: string) => {
    if (window.confirm('Are you sure you want to delete this season?')) {
      try {
        await deleteSeason(seasonId);
        setSeasons((prev) => prev.filter((s) => s._id !== seasonId)); // Update local state to remove season
      } catch (error) {
        console.error('Failed to delete season:', error);
        alert('Error deleting season');
      }
    }
  };

  const handleAddGames = (season: Season) => {
    setSelectedSeason(season);
    setShowModal(true);
  };

  const togglePopover = (seasonId: string) => {
    setPopoverOpen((prev) => ({ ...prev, [seasonId]: !prev[seasonId] }));
  };

  useEffect(() => {
    fetchSeasons();
    fetchUserAttributes(); // Fetch user attributes when the component mounts
  }, []);

  const getSeasonStatus = (season: Season) => {
    const today = new Date();
    const seasonStartDate = new Date(season.startDate);

    if (season.current) {
      return { text: 'Current', color: 'green' };
    } else if (seasonStartDate > today) {
      return { text: 'Future', color: 'black' };
    } else {
      return { text: 'Past', color: 'red' };
    }
  };

  return (
    <div className="mainMenuContainer">
      <TopNavBar />
      <h2>Seasons</h2>
      <button className="addFab" onClick={() => navigate('/create-season')}>
        +
      </button>
      {error && <p className="error">{error}</p>}
      {loading || !isUserFetched ? (
        <div className="loadingSpinner">Loading...</div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Year</th>
              <th>Season</th>
              <th>Location</th>
              <th>League</th>
              <th>Status</th>
              {isAdmin && <th>Admin</th>}
            </tr>
          </thead>
          <tbody>
            {seasons.map((season) => {
              const { text, color } = getSeasonStatus(season);
              return (
                <tr key={season._id}>
                  <td>{new Date(season.startDate).getFullYear()}</td>
                  <td>{season.season}</td>
                  <td>{season.location}</td>
                  <td>{season.league}</td>
                  <td style={{ color }}>{text}</td>
                  {isAdmin && (
                    <td>
                      <Button id={`Popover-${season._id}`} className="editButton">
                        Actions
                      </Button>
                      <Popover
                        placement="bottom"
                        isOpen={popoverOpen[season._id] || false}
                        target={`Popover-${season._id}`}
                        toggle={() => togglePopover(season._id)}
                        className="popoverAction"
                      >
                        <PopoverBody className="popoverBody">
                          <button className="popoverItem" onClick={() => navigate(`/season-games/${season._id}`)}>
                            View Games
                          </button>
                          <button className="popoverItem" onClick={() => navigate(`/season-teams/${season._id}`)}>
                            View Teams
                          </button>
                          <button className="popoverItem" onClick={() => navigate(`/edit-season/${season._id}`)}>
                            Edit
                          </button>
                          <button className="popoverItem" onClick={() => handleDeleteSeason(season._id)}>
                            Delete
                          </button>
                          <button className="popoverItem" onClick={() => handleAddGames(season)}>
                            Add Games
                          </button>
                        </PopoverBody>
                      </Popover>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <BottomNavBar />
      {showModal && selectedSeason && <AddGameModal season={selectedSeason} onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default ManageSeasons;
